import { createHttpRequests } from 'api/requests';
import { GetFormResponse, FormsResponse } from './types';
import endpoints from 'constants/endpoints';
import axiosServices from 'api';

interface GetFormsParams {
  page?: string;
  pageSize?: string;
  searchTerm?: string;
  status?: string;
}

const { getRequest, postRequest, deleteRequest } = createHttpRequests(axiosServices);

export const getForms = async (params?: GetFormsParams): Promise<FormsResponse | any> => {
  try {
    const response = await getRequest<FormsResponse>(endpoints.forms, params);
    return response;
  } catch (error) {
    return error;
  }
};

export const getForm = async (id: string): Promise<GetFormResponse | any> => {
  try {
    const response = await getRequest<GetFormResponse>(endpoints.form(id));
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteForm = async (id: string): Promise<any> => {
  try {
    const response = await deleteRequest<any>(endpoints.form(id));
    return response;
  } catch (error) {
    return error;
  }
};
