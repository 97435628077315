import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdPerson, MdHome, MdLock, MdOutlineShoppingCart } from 'react-icons/md';

// panel Imports
// todo: silinecekler
// import MainDashboard from 'views/admin/default';
// import NFTMarketplace from 'views/admin/marketplace';
// import AdminProfile from 'views/admin/profile';
// import DataTables from 'views/admin/dataTables';

// Auth Imports
import SignInCentered from 'views/auth/signIn';

// Page Imports

// Constant Imports
import { USER_ROLES } from 'constants/users';
import Students from 'pages/students';
import Teachers from 'pages/teachers';
import LessonSubjects from 'pages/lesson-subjects';
import Profile from 'pages/profile';
import Classes from 'pages/classes';
import Salons from 'pages/salons';
import Reservations from 'pages/reservations';
import ReservationDetail from 'pages/reservations/detail';
import ClassDetail from 'pages/classes/detail';
import Home from 'pages/home';
import Forms from 'pages/admin/forms';
import { FaCalendarCheck, FaChalkboardTeacher, FaClipboardList, FaSchool, FaUserGraduate } from 'react-icons/fa';

const routes = [
  // {
  //   name: 'Alt Kurumlar',
  //   layout: '/panel',
  //   path: '/sub-organizations',
  //   icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
  //   component: <SubOrganizations />,
  //   withPermission: {
  //     roles: [USER_ROLES.SUPER_ADMIN]
  //   }
  // },
  {
    name: 'Ana Sayfa',
    layout: '/panel',
    path: '/home',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: <Home />
  },
  {
    name: 'Öğrenciler',
    layout: '/panel',
    path: '/students',
    icon: <Icon as={FaUserGraduate} width='20px' height='20px' color='inherit' />,
    component: <Students />,
    withPermission: {
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
    }
  },
  {
    name: 'Sınıflar',
    layout: '/panel',
    path: '/classes',
    icon: <Icon as={FaSchool} width='20px' height='20px' color='inherit' />,
    component: <Classes />,
    withPermission: {
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
    }
  },
  {
    name: 'Sınıf Detay',
    layout: '/panel',
    path: '/classes/:id',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: <ClassDetail />,
    withPermission: {
      roles: [USER_ROLES.TEACHER, USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
    },
    sidebarDisplay: false
  },
  {
    name: 'Öğretmenler',
    layout: '/panel',
    path: '/teachers',
    icon: <Icon as={FaChalkboardTeacher} width='20px' height='20px' color='inherit' />,
    component: <Teachers />,
    withPermission: {
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
    }
  },
  {
    name: 'Salonlar',
    layout: '/panel',
    path: '/salons',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: <Salons />,
    withPermission: {
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
    }
  },
  {
    name: 'Rezervasyonlar',
    layout: '/panel',
    path: '/reservations',
    icon: <Icon as={FaCalendarCheck} width='20px' height='20px' color='inherit' />,
    component: <Reservations />,
    withPermission: {
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN, USER_ROLES.STUDENT, USER_ROLES.TEACHER]
    }
  },
  // {
  //   name: 'Dersler',
  //   layout: '/panel',
  //   path: '/lessons',
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: <Lessons />,
  //   withPermission: {
  //     roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
  //   }
  // },
  {
    name: 'Kazanımlar',
    layout: '/panel',
    path: '/lessons/subjects',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: <LessonSubjects />,
    withPermission: {
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
    },
    sidebarDisplay: false
  },
  {
    name: 'Profilim',
    layout: '/panel',
    path: '/profile',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: <Profile />,
    withPermission: {
      roles: [USER_ROLES.STUDENT]
    }
  },
  {
    name: 'Öğrenci Profili',
    layout: '/panel',
    path: '/profile/:id',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: <Profile />,
    withPermission: {
      roles: [USER_ROLES.TEACHER, USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
    },
    sidebarDisplay: false
  },
  {
    name: 'Rezervasyon Detay',
    layout: '/panel',
    path: '/reservations/:id',
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: <ReservationDetail />,
    withPermission: {
      roles: [USER_ROLES.TEACHER, USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
    },
    sidebarDisplay: false
  },
  {
    name: 'Başvurular',
    layout: '/panel',
    path: '/forms',
    icon: <Icon as={FaClipboardList} width='20px' height='20px' color='inherit' />,
    component: <Forms />,
    withPermission: {
      roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.ADMIN]
    }
  },
  {
    name: 'Sign In',
    layout: '/auth',
    path: '/sign-in',
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: <SignInCentered />,
    sidebarDisplay: false
  }
];

export default routes;
