import { VStack, Text } from '@chakra-ui/react';
import { Form } from 'api/services/forms/types';

interface FormDetailsModalProps {
  form: Form;
}

export default function FormDetailsModal({ form }: FormDetailsModalProps) {
  return (
    <VStack align="start" spacing={3}>
      <Text><strong>Öğrenci Adı:</strong> {form.studentName}</Text>
      <Text><strong>Öğrenci Soyadı:</strong> {form.studentSurname}</Text>
      <Text><strong>Okul:</strong> {form.schoolName}</Text>
      <Text><strong>Sınıf:</strong> {form.class}</Text>
      <Text><strong>Öğrenci Telefon:</strong> {form.studentGsm ? form.studentGsm : 'Numara Yok'}</Text>
      <Text><strong>Veli Adı:</strong> {form.parentName}</Text>
      <Text><strong>Veli Telefon:</strong> {form.parentGsm}</Text>
      <Text>
        <strong>Başvuru Tarihi:</strong>{' '}
        {new Date(form.submissionDate).toLocaleDateString('tr-TR')}
      </Text>
      <Text>
        <strong>Durum:</strong>{' '}
        {form.status === 1 ? 'İncelendi' : 'Bekliyor'}
      </Text>
      {form.reviewNotes && (
        <Text><strong>İnceleme Notları:</strong> {form.reviewNotes}</Text>
      )}
    </VStack>
  );
}
