import { Document, Page, StyleSheet, Text, View, Font } from '@react-pdf/renderer';
import { Form } from 'api/services/forms/types';

// Roboto fontunu base64 olarak ekleyelim
Font.register({
  family: 'Roboto',
  src: `data:font/truetype;base64,AAEAAAASAQAABAAgR0RFRgBKAAgAAAHMAAAAKEdQT1MF4g4KAAADaAAAAKRHU1VCgxaCxQAAAhAAAAA0T1MvMnSaAagAAALEAAAAYGNtYXABOgGnAAADJAAAAGRjdnQgK6gHnQAAAoQAAABUZnBnbXf4YKsAAAcYAAABvGdhc3AACAATAAABLAAAAAxnbHlmQQB5qwAABKgAAAWgaGRteA8MCwMAAAE4AAAAEGhlYWT8atJ6AAACRAAAADZoaGVhCroFpwAAAYQAAAAkaG10eCNeBLgAAAGoAAAANGxvY2EGpgcyAAABNAAAABxtYXhwAjMDCQAAAUQAAAAgbmFtZRudOGoAAAHwAAABdHBvc3T/bQBkAAABZAAAACBwcmVwomb6yQAAA9gAAAFJAAEAAgAIAAL//wAPAAAAAQAAAAwJCAQCBgUGBQABAAAAARYBBAAEAAAAAgAAAAAAAAAAAAAAAwAAASYAAQACACAALwAAAEUARABEAEQAAQAAAAgAJAAyAAJERkxUABJsYXRuAA4AAAAAAAAAAA4ABAACAA4AEAAqADIAAQAAAAEACAABAAAAAQAIAAIAMgAGAEoATABOAFAAUgBUAAEAAAABAAgAAQAGAAUAAQABAE8AAQAAAAEACAABBgACAAEAAQBPAAEAAAABAAgAAgAcAAYASgBMAE4AUABSAFQAAgACAE8ATwAAAAEAAAAKAB4ALAABREZMVAAIbGF0bgAOAAQAAAAA//8AAQAAAAFrZXJuAAgAAAABAAAAAQAEAAIAAAABAAgAAQBmAAQAAAAIABoAIAAmADAANgA8AEIASAABAAb/7AABAAb/9gABAAb/4gABAAb/7AABAAb/9gABAAb/4gABAAb/7AABAAb/9gACAEL/9gBU//YAAgABAEoAVAAAAQAAAAoAHgAsAAFERkxUAAhsYXRuAA4ABAAAAAD//wABAAAAAWtlcm4ACAAAAAEAAAABAAQAAgAAAAEACAABAAwAAQAIAAEABv/2AAEAAQBKAAAAAQAAAAoAHgAsAAFERkxUAAhsYXRuAA4ABAAAAAD//wABAAAAAWtlcm4ACAAAAAEAAAABAAQAAgAAAAEACAABAAwAAQAIAAEABv/sAAEAAQBUAAAAAQAAAAoAHgAsAAFERkxUAAhsYXRuAA4ABAAAAAD//wABAAAAAWtlcm4ACAAAAAEAAAABAAQAAgAAAAEACAABAAwAAQAIAAEABv/iAAEAAQBUAAAAAgACABQAHAADAAEABAABAAAABwABAAEAAQABAAEAAAAKAB4ALAABREZMVAAIY2FwcwAOY3BzcAAUbGF0bgAaAAQAAAAA//8AAQAAAAFsb2NsAAgAAAABAAAAAQAEAAEAAAABAAgAAQAGAAIAAAABAAEACAABAAAACgAeACwAAURGTFQACGxhdG4ADgAEAAAAAP//AAEAAAABbG9jbAAIAAAAAQAAAAEABAABAAAAAgABAAgACQAAAAEAAAAKACAAKgABREZMVAAIbGF0bgAOAAQAAAAA//8AAQAAAAFrZXJuAAgAAAABAAAAAQAEAAIAAAABAAgAAQAMAAEACAABAAb/7AABAAEAVAAAAAEAAAAKACAAKgABREZMVAAIbGF0bgAOAAQAAAAA//8AAQAAAAFrZXJuAAgAAAABAAAAAQAEAAIAAAABAAgAAQAMAAEACAABAAb/9gABAAEAVAAAAQAAAAEACAABAAAACgAUAB4AAkRGTFQAEmxhdG4ADgAAAAD//wABAAEAAAABa2VybgAIAAAAAQAAAAEABAABAAAAAQAIAAEABgABAAEAAQBUAAA=`,
});

interface GenerateFormsFileProps {
  data: {
    forms: Form[];
  }
}

const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontFamily: 'Roboto',
    fontSize: 9,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    height: 20,
    alignItems: 'center',
  },
  tableCell: {
    padding: 3,
    fontFamily: 'Roboto',
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    borderBottomStyle: 'solid',
    backgroundColor: '#f0f0f0',
    height: 20,
    alignItems: 'center',
    marginBottom: 0,
  },
  headerCell: {
    padding: 3,
    fontWeight: 'bold',
    fontFamily: 'Roboto',
    fontSize: 10,
  },
  cellNumber: { width: '5%' },
  cellName: { width: '20%' },
  cellSchool: { width: '20%' },
  cellClass: { width: '5%' },
  cellStudentGsm: { width: '13%' },
  cellParentName: { width: '20%' },
  cellParentGsm: { width: '12%' },
  cellStatus: { width: '5%' },
});

const PAGE_HEIGHT = 842 - 20;
const ROW_HEIGHT = 20;
const HEADER_HEIGHT = 20;
const FOOTER_HEIGHT = 20;

const ITEMS_PER_PAGE = Math.floor((PAGE_HEIGHT - HEADER_HEIGHT - FOOTER_HEIGHT) / ROW_HEIGHT);

const GenerateFormsFile = ({ data }: GenerateFormsFileProps) => {
  const pages = [];
  for (let i = 0; i < data.forms.length; i += ITEMS_PER_PAGE) {
    pages.push(data.forms.slice(i, i + ITEMS_PER_PAGE));
  }

  return (
    <Document>
      {pages.map((pageItems, pageIndex) => (
        <Page key={`page_${pageIndex}`} size="A4" style={styles.page}>
          <View style={styles.tableHeader}>
            <Text style={{...styles.headerCell, ...styles.cellNumber}}>No</Text>
            <Text style={{...styles.headerCell, ...styles.cellName}}>Öğrenci Adı Soyadı</Text>
            <Text style={{...styles.headerCell, ...styles.cellSchool}}>Okul</Text>
            <Text style={{...styles.headerCell, ...styles.cellClass}}>Sınıf</Text>
            <Text style={{...styles.headerCell, ...styles.cellStudentGsm}}>Öğrenci GSM</Text>
            <Text style={{...styles.headerCell, ...styles.cellParentName}}>Veli Adı</Text>
            <Text style={{...styles.headerCell, ...styles.cellParentGsm}}>Veli GSM</Text>
          </View>

          {pageItems.map((form, index) => (
            <View style={styles.tableRow} key={`row_${pageIndex}_${index}`}>
              <Text style={{...styles.tableCell, ...styles.cellNumber}}>
                {pageIndex * ITEMS_PER_PAGE + index + 1}
              </Text>
              <Text style={{...styles.tableCell, ...styles.cellName}}>
                {form.studentName} {form.studentSurname}
              </Text>
              <Text style={{...styles.tableCell, ...styles.cellSchool}}>{form.schoolName}</Text>
              <Text style={{...styles.tableCell, ...styles.cellClass}}>{form.class}</Text>
              <Text style={{...styles.tableCell, ...styles.cellStudentGsm}}>{form.studentGsm}</Text>
              <Text style={{...styles.tableCell, ...styles.cellParentName}}>{form.parentName}</Text>
              <Text style={{...styles.tableCell, ...styles.cellParentGsm}}>{form.parentGsm}</Text>
            </View>
          ))}
          
          <Text style={{
            position: 'absolute',
            bottom: 8,
            right: 8,
            fontSize: 8,
          }}>
            Sayfa {pageIndex + 1} / {pages.length}
          </Text>
        </Page>
      ))}
    </Document>
  );
};

export default GenerateFormsFile; 