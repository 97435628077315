import {
  Box,
  Button,
  Checkbox,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  SimpleGrid,
  useColorModeValue
} from '@chakra-ui/react';
import { getForm, getForms, deleteForm } from 'api/services/forms';
import DataTable from 'components/shared/data-table';
import { Column } from 'components/shared/data-table/types';
import { useEffect, useState } from 'react';
import { useCustomSnackbar } from 'utils/custom-snackbar';
import { Form, FormsResponse } from 'api/services/forms/types';
import FormDetailsModal from 'components/forms/form-details-modal';
import { useUI } from 'contexts/UIContext';
import { IoFilter } from 'react-icons/io5';
import ControlledMenuItem from 'components/with-controlled/menu-item';
import { USER_ROLES } from 'constants/users';
import { pdf } from '@react-pdf/renderer';
import saveAs from 'file-saver';
import GenerateFormsFile from 'components/forms/generate-forms-file';

const columnsDataCheck: Column<Form>[] = [
  { name: 'studentName', displayName: 'Öğrenci Adı' },
  { name: 'studentSurname', displayName: 'Öğrenci Soyadı' },
  { name: 'schoolName', displayName: 'Okul' },
  { 
    name: 'studentGsm', 
    displayName: 'Öğrenci GSM',
  },
  {
    name: 'status',
    displayName: 'Durum'
  }
];

const initialFormsState: FormsResponse = {
  totalCount: 0,
  items: [],
  page: 1,
  pageSize: 10,
  totalPages: 1
};

export default function Forms() {
  const { showModal } = useUI();
  const snackbar = useCustomSnackbar();
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState<FormsResponse>(initialFormsState);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [showReviewed, setShowReviewed] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);

  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const menuBg = useColorModeValue('white', 'navy.800');

  const fetchForms = async (page?: number, pageSize?: number, status?: boolean) => {
    try {
      setLoading(true);
      const formsResponse = await getForms({
        page: page?.toString(),
        pageSize: pageSize?.toString(),
        status: status ? '1' : undefined
      });

      const forms = formsResponse.items.map((form: Form) => ({
        ...form,
        status: form.status === 1 ? 'İncelendi' : 'Bekliyor',
        studentGsm: form.studentGsm != "" && form.studentGsm != null ? form.studentGsm : 'Numara Yok'
      }));

      setForms({ ...formsResponse, items: forms });
    } catch (error) {
      console.log(error);
      snackbar('Form listesi alınırken bir hata oluştu', 'error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchForms(page, pageSize, showReviewed);
  }, []);

  const handleViewDetails = async (id: number) => {
    try {
      const formResponse = await getForm(id.toString());
      showModal(<FormDetailsModal form={formResponse} />, 'Form Detayları');
    } catch (error) {
      console.log(error);
      snackbar('Form detayları alınırken bir hata oluştu', 'error');
    }
  };

  const handleDeleteClick = async (id: number) => {
    try {
      const deleteResponse = await deleteForm(id.toString());

      if (deleteResponse?.status === 400) {
        throw new Error(deleteResponse.data);
      }

      snackbar('Başarıyla silindi', 'success');
      await fetchForms(page, pageSize, showReviewed);
    } catch (err: any) {
      snackbar(err.message, 'error');
    }
  };

  const onPaginationChangeCallback = async (pageIndex: number, pageSize: number) => {
    setPage(pageIndex + 1);
    setPageSize(pageSize);
    await fetchForms(pageIndex + 1, pageSize, showReviewed);
  };

  const handleChangeShowReviewed = async (checked: boolean) => {
    setShowReviewed(checked);
    await fetchForms(page, pageSize, checked);
  };

  const toDataURL = async (url: string): Promise<string | ArrayBuffer | null> => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Görsel base64 formatına dönüştürülemedi:', error);
      return null;
    }
  };

  const getAllForms = async () => {
    try {
      const formsResponse = await getForms({
        page: '1',
        pageSize: '10000',
        status: showReviewed ? '1' : undefined
      });

      return formsResponse.items.map((form: Form) => ({
        ...form,
        status: form.status === 1 ? 'İncelendi' : 'Bekliyor',
        studentGsm: form.studentGsm || 'Numara Yok',
        submissionDate: new Date(form.submissionDate).toLocaleDateString('tr-TR')
      }));
    } catch (error) {
      console.error('Form listesi alınamadı:', error);
      throw new Error('Form listesi alınırken bir hata oluştu');
    }
  };

  const handleClickExportPdf = async () => {
    try {
      setPdfLoading(true);
      const fileName = `form-basvurulari-${new Date().toLocaleDateString('tr-TR')}.pdf`;
      
      // Tüm formları al
      const allForms = await getAllForms();

      const data = {
        forms: allForms
      };

      const blob = await pdf(<GenerateFormsFile data={data} />).toBlob();
      saveAs(blob, fileName);
    } catch (error) {
      console.error('PDF oluşturma hatası:', error);
      snackbar('PDF oluşturulurken bir hata oluştu', 'error');
    } finally {
      setPdfLoading(false);
    }
  };

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid spacing={2}>
        <Flex justifyContent='space-between' w='100%' mb='10px'>
          <Button
            colorScheme='whatsapp'
            variant='outline'
            onClick={handleClickExportPdf}
            isDisabled={forms.items.length === 0}
            isLoading={pdfLoading}
            loadingText="PDF Oluşturuluyor"
          >
            PDF'e Aktar
          </Button>
          <Menu>
            <MenuButton p='0px'>
              <Button colorScheme='telegram' rightIcon={<IoFilter />}>
                Filtreler
              </Button>
            </MenuButton>
            <MenuList boxShadow={shadow} mt='10px' borderRadius='20px' bg={menuBg} border='none'>
              <Flex flexDirection='column' p='10px'>
                <ControlledMenuItem
                  _hover={{ bg: 'none' }}
                  _focus={{ bg: 'none' }}
                  borderRadius='8px'
                  px='14px'
                  closeOnSelect={false}
                  roles={[USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN]}
                >
                  <Checkbox checked={showReviewed} onChange={(e) => handleChangeShowReviewed(e.target?.checked)}>
                    İncelenen başvuruları göster
                  </Checkbox>
                </ControlledMenuItem>
              </Flex>
            </MenuList>
          </Menu>
        </Flex>
        <DataTable
          tableData={forms.items}
          totalCount={forms.totalCount}
          onPaginationChangeCallback={onPaginationChangeCallback}
          columns={columnsDataCheck}
          handleDeleteClick={handleDeleteClick}
          loading={loading}
          displayForms
          displayFormClick={handleViewDetails}
        />
      </SimpleGrid>
    </Box>
  );
}
